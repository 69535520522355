import { Injectable, signal, WritableSignal } from '@angular/core';
import { NgxDropdownOption } from 'ngx-dropdown';
import { BehaviorSubject, filter, Observable } from 'rxjs';
import { BulkInput, FilterSelectionInterface, HeaderButtpnType, RegConfig } from '../models';
import { UserPreference } from '../models/user-preference';

@Injectable({ providedIn: 'root' })
export class InternalService {
  private onChange$ = new BehaviorSubject<HeaderButtpnType | null>(null);
  private bulkInput$ = new BehaviorSubject<BulkInput | null>(null);
  private selectedProgram$ = new BehaviorSubject<NgxDropdownOption | null>(null);
  private selectedReg$ = new BehaviorSubject<NgxDropdownOption | null>(null);
  private regConfigState$ = new BehaviorSubject<null | RegConfig>(null);
  private filterSelection$ = new BehaviorSubject<FilterSelectionInterface | null>(null);
  private errorLoadingData$ = new BehaviorSubject<boolean>(false);
  public editMode$ = new BehaviorSubject<boolean>(false);

  public userPreferenceSignal: WritableSignal<UserPreference | null> = signal(null);

  public setErrorLoadingData(newState: boolean): void {
    this.errorLoadingData$.next(newState);
  }

  get getErrorLoadingData(): Observable<boolean> {
    return this.errorLoadingData$.asObservable();
  }

  public setFilterSelectionState(newState: FilterSelectionInterface | null): void {
    this.filterSelection$.next(newState);
  }

  get getFilterSelectionState(): Observable<FilterSelectionInterface | null> {
    return this.filterSelection$.asObservable()
  }

  public setRegViewConfigState(newState: null | RegConfig): void {
    this.regConfigState$.next(newState);
  }

  get selectedRegViewConfigFilterState(): Observable<null | RegConfig> {
    return this.regConfigState$.asObservable();
  }


  // Emit "null" and then filter it so the next tab that subscribe to this observable dont apply the same values again
  // Example: edit on open tab, add value for entire day then change to baseline tab, should not execute the applyBulk function automatically
  public setBulkInputs(newState: null | BulkInput): void {
    this.bulkInput$.next(newState);
    this.bulkInput$.next(null);
  }

  // Emit "null" and then filter it so the next tab that subscribe to this observable dont apply the same values again
  // Example: edit on open tab, add value for entire day then change to baseline tab, should not execute the applyBulk function automatically
  get getBulkInputs$(): Observable<BulkInput | null> {
    return this.bulkInput$.asObservable();
  }

  // Emit "null" to this observable it doesn't trigger the same values again
  public setOnChange(newState: null | HeaderButtpnType): void {
    this.onChange$.next(newState);
    this.onChange$.next(null);
  }

  // Emit "null" and then filter it to this observable so it doesn't apply the same values again
  get getOnChange$(): Observable<HeaderButtpnType | null> {
    return this.onChange$.asObservable().pipe(filter((el:HeaderButtpnType | null) => !!el));
  }

  public setSelectedProgram(newState: NgxDropdownOption | null) {
    this.selectedProgram$.next(newState);
  }

  get getSelectedProgram(): Observable<NgxDropdownOption | null> {
    return this.selectedProgram$.asObservable();
  }

  public setSelectedReg(newState: NgxDropdownOption | null) {
    this.selectedReg$.next(newState);
  }

  get getSelectedReg(): Observable<NgxDropdownOption | null> {
    return this.selectedReg$.asObservable();
  }

}
