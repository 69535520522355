import { ChangeDetectionStrategy, Component, computed, EventEmitter, OnInit, Output, signal, WritableSignal } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { switchMap, tap } from 'rxjs';
import { RegConfig, TabOption, TabOptionsEnum } from '../../shared/models';
import { DataViewModelService } from '../../shared/services/data-vm.service';


@Component({
  selector: 'dr-customer-offers-ui-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsComponent implements OnInit {
  options: WritableSignal<TabOption[]> = signal([]);
  readonly visibleOptions = computed(() => {
    return this.options()
      .filter(opt => opt.show);
  });
  @Output() tabChange = new EventEmitter<TabOption>();

  protected selectedOption: TabOption | undefined = undefined;

  constructor(private router: Router, private translate: TranslateService, private dataVMService: DataViewModelService) {}

  ngOnInit(): void {
    this.dataVMService.selectedRegFormControl.valueChanges.pipe(
      switchMap((f: any) => {
        return this.dataVMService.getRegViewConfig().pipe(
          tap((r: RegConfig | null) => {
            if (!r) return;
            const currentUrl: string = this.router.url;
            this.options.set([
              {
                show: true,
                route: 'open',
                name: this.translate.instant('COM.TABS.OPEN.TAB_TITLE'),
                value: TabOptionsEnum.Open,
                selected: currentUrl.includes('open'),
                descriptionTitle: this.translate.instant('COM.TABS.OPEN.TITLE'),
                descriptionText:
                  this.translate.instant('COM.TABS.OPEN.DESCRIPTION') +
                  r.minimumValue +
                  this.translate.instant('COM.TABS.LABELS.AND') +
                  r.maximumValue,
                showEditButton: true,
                bulkFunctionality: true,
                exportFunctionality: true,
                optOut: true
              },
              {
                show: r.tab_display.display_da_tab ?? false,
                route: 'da',
                name: this.translate.instant('COM.TABS.DECLARED_AVAILABILITY.TAB_TITLE'),
                value: TabOptionsEnum.Declared_Availability,
                selected: currentUrl.includes('da'),
                descriptionTitle: this.translate.instant('COM.TABS.DECLARED_AVAILABILITY.TITLE'),
                descriptionText:
                this.translate.instant('COM.TABS.DECLARED_AVAILABILITY.DESCRIPTION') +
                  r.minimumValue +
                  this.translate.instant('COM.TABS.LABELS.AND') +
                  r.maximumValue,
                showEditButton: true,
                bulkFunctionality: true,
                exportFunctionality: false,
                optOut: false
              },
              {
                show: r.tab_display.display_cleared_tab,
                route: 'cleared',
                name: this.translate.instant('COM.TABS.CLEARED.TAB_TITLE'),
                value: TabOptionsEnum.Cleared,
                selected: currentUrl.includes('clear'),
                descriptionTitle: this.translate.instant('COM.TABS.CLEARED.TITLE'),
                descriptionText: '',
                showEditButton: false,
                bulkFunctionality: false,
                exportFunctionality: true,
                optOut: false
              },
              {
                show: r.tab_display.display_schedule_tab,
                route: 'schedule',
                name: this.translate.instant('COM.TABS.SCHEDULE.TAB_TITLE'),
                value: TabOptionsEnum.Schedule,
                selected: currentUrl.includes('schedule'),
                descriptionTitle: this.translate.instant('COM.TABS.SCHEDULE.TITLE'),
                descriptionText:
                  this.translate.instant('COM.TABS.SCHEDULE.DESCRIPTION') +
                  r.minimumValue +
                  this.translate.instant('COM.TABS.LABELS.AND') +
                 r.maximumValue,
                showEditButton: true,
                bulkFunctionality: true,
                exportFunctionality: false,
                optOut: true
              },
              {
                show: r.tab_display.display_baselines_tab,
                route: 'baselines',
                name: this.translate.instant('COM.TABS.BASELINES.TAB_TITLE'),
                value: TabOptionsEnum.Baselines,
                selected:  currentUrl.includes('baselines'),
                descriptionTitle: this.translate.instant('COM.TABS.BASELINES.TITLE'),
                descriptionText: '',
                showEditButton: true,
                bulkFunctionality: true,
                exportFunctionality: false,
                optOut: false
              }
            ]);
            this.selectedOption = this.options().find(f => f.selected) ;
          }))
      })
    ).subscribe();
  }

  protected onTabChange(e: MatButtonToggleChange): void {
    this.selectedOption = e.value;
    this.tabChange.emit(this.selectedOption);
    this.router.navigate([this.selectedOption?.route]);
  }
}
