<mat-card>
    <mat-card-content class="card">
            <div class="container-top" *ngIf="{ programsData: (programsData$ | async) } as vm">
                    <div class="container-top-left">
                        <div class="flex-box mb-16">


                    <lib-ngx-dropdown *ngIf="vm.programsData" class="ngx-dropdown" #ngx_program_dropdown
                      [options]="vm.programsData"
                      [selected]="selectedProgramFormControl.value"
                      [selectedObject]="selectedProgramFormControl"
                      [border]="true"
                      [multiple]="false"
                      [invalid]="false"
                      [required]="false"
                      [disabled]="!isProgramListLoaded"
                      [title]="'COM.FILTERS.PROGRAMS' | translate"
                      [hasInternalReset]="false"
                      [hint]="''"
                      [width]="'100%'" style="width:33%"
                      class="mr-16">
                    </lib-ngx-dropdown>

                    <lib-ngx-dropdown #ngx_reg_dropdown
                      [options]="(registrationsData$ | async)"
                      [selected]="selectedRegFormControl.value"
                      [selectedObject]="selectedRegFormControl"
                      [border]="true"
                      [multiple]="false"
                      [invalid]="false"
                      [required]="false"
                      [disabled]="false"
                      [title]="'COM.FILTERS.REGISTRATIONS' | translate"
                      [hint]="''"
                      [hasInternalReset]="false"
                      [width]="'100%'" style="width:33%"
                      class="mr-16">
                    </lib-ngx-dropdown>

                    <lib-ngx-date-range-picker #ngx_drp
                        [datePickerType]="'weekPicker'"
                        [title]="'COM.FILTERS.TIME_PERIOD' | translate"
                        [border]="true"
                        [isHint]="false"
                        [disabled]="false"
                        [invalid]="false"
                        style="width:33%"
                        class="mr-16"
                        [selectedDate]="selectedDateFormControl">
                    </lib-ngx-date-range-picker>


                </div>
               </div>
               <div class="container-top-right pt-10">
                <button
                (click)="apply()"
                mat-flat-button
                color="primary"
                class="round-button apply-btn">
                <b>{{'COM.BUTTONS.APPLY' | translate}}</b>
            </button>
            </div>

            </div>
    </mat-card-content>
</mat-card>
