import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ScheduleRequest, ScheduleResponse } from '@dr-customer-offers-ui/lib-interfaces';
import { APIData } from 'common-utils/dist/models/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiEndpointsService } from '../api-endpoint/api-endpoints.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  constructor(
    private http: HttpClient,
    private apiService: ApiEndpointsService,
  ) { }

  getSchedule(registration_id: string): Observable<ScheduleResponse[]> {
    const { baseUrl } = this.apiService;
    const url = `${baseUrl}/registrations/` + registration_id + `/schedule`;
    return this.http.get<APIData<ScheduleResponse[]>>(url, {withCredentials: true}).pipe(
      map((res) => this.extractScheduleData(res))
    );
  }

  putSchedule(registration_id: string, offers: ScheduleRequest[]): Observable<ScheduleResponse[]> {
    const { baseUrl } = this.apiService;
    const url = `${baseUrl}/registrations/` + registration_id + `/schedule`;
    return this.http.put<APIData<ScheduleResponse[]>>(url, offers, {withCredentials: true}).pipe(
        map((res) => this.extractScheduleData(res))
      );
  }

  postSchedule(registration_id: string, offers: ScheduleRequest[]): Observable<ScheduleResponse[]> {
    const { baseUrl } = this.apiService;
    const url = `${baseUrl}/registrations/` + registration_id + `/schedule`;
    return this.http.post<APIData<ScheduleResponse[]>>(url, offers, {withCredentials: true}).pipe(
        map((res) => this.extractScheduleData(res))
      );
  }

  private extractScheduleData(res: APIData<ScheduleResponse[]>): ScheduleResponse[] {
    const payload = res.data;
    return payload as ScheduleResponse[];
  }
}
