import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  BaselineRequest,
  BaselineResponse,
  DARequest,
  DAResponse,
  GroupedOffersDataModel,
  OfferRequest,
  OfferResponse,
  OperationalGroup,
  ProgramResponse,
  RegistrationResponse,
  RegistrationViewConfigResponse,
  ScheduleRequest,
  ScheduleResponse
} from '@dr-customer-offers-ui/lib-interfaces';
import {
  BaselineService,
  DeclaredAvailabilityService,
  OfferService,
  ProgramService,
  RegistrationService,
  RegistrationViewConfigService,
  ScheduleService
} from '@dr-customer-offers-ui/lib-services';
import { Timezone } from 'common-utils/dist/models/time';
import * as moment from 'moment';
import { NgxDateRangePickerOutput } from 'ngx-date-range-picker';
import { NgxDropdownOption } from 'ngx-dropdown';
import { DataModel, DetailTableCellModel, NgxIntervalDataGridRowModel, NgxIntervalDataGridService, OperationalHours, TableDataType, TableDataTypes } from 'ngx-interval-data-grid';
import { BehaviorSubject, catchError, EMPTY, forkJoin, map, Observable, of, shareReplay, Subject, switchMap } from 'rxjs';
import { DayOfWeekModel, ExportReportOutput, GroupedData, RegConfig, transformRegConfig } from '../models';
import { DaterangeISO } from '../models/daterange';
import { DataModelService } from './data-model.service';
import { DateService } from './date.service';
import { InternalService } from './internal.service';

@Injectable({ providedIn: 'root' })
export class DataViewModelService {
  private refreshOpenTabTrigger$ = new Subject<void>();
  private refreshDATrigger$ = new Subject<void>();
  private refreshBaselineTrigger$ = new Subject<void>();
  private refreshScheduleTrigger$ = new Subject<void>();
  private regViewConfigCache: Map<string, Observable<RegConfig | null>> = new Map<string, Observable<RegConfig | null>>();

  private groupedDataSubject = new BehaviorSubject<{ open: GroupedData | null; clear: GroupedData | null }>({open: null, clear: null});
  public groupedData$ = this.groupedDataSubject.asObservable();
  public isGroupedOffersSignal = signal<boolean>(false);
  formFilter: FormGroup;

  constructor(
    private programService: ProgramService,
    private registrationService: RegistrationService,
    private registrationViewConfigService: RegistrationViewConfigService,
    private internalState: InternalService,
    private dateService: DateService,
    private offerService: OfferService,
    private scheduleService: ScheduleService,
    private baselineService: BaselineService,
    private dataModelService: DataModelService,
    private ngxService: NgxIntervalDataGridService,
    private daService: DeclaredAvailabilityService,
    private fb: FormBuilder
  ) {
    this.formFilter = this.fb.group({
      filteredDate: new FormControl<NgxDateRangePickerOutput | null>(null),
      filteredProgram: new FormControl<NgxDropdownOption | null>(null),
      filteredReg: new FormControl<NgxDropdownOption | null>(null)
    })
  }

  get selectedDateFormControl(): FormControl {
    return this.formFilter.get('filteredDate') as FormControl;
  }

  get selectedProgramFormControl(): FormControl {
    return this.formFilter.get('filteredProgram') as FormControl;
  }

  get selectedRegFormControl(): FormControl {
    return this.formFilter.get('filteredReg') as FormControl;
  }

  getProgramsList(): Observable<NgxDropdownOption[] | null> {
    return this.programService.getPrograms().pipe(
      map((programs: ProgramResponse[]) => {
        let programsData: NgxDropdownOption[] = [];
        const userPreference = this.internalState.userPreferenceSignal();
        if (programs && programs instanceof Array) {
          programsData = programs.map((program: ProgramResponse) => {
            const programsMeta: NgxDropdownOption = {
              value: this.getLocalisedLabel(program.display_labels, userPreference?.apiLocale ?? '', 'en_US'),
              id: program.id
            };
            return programsMeta;
          });
        }
        const sortedProgsData = programsData.length ? programsData.sort((a, b) => a.value.localeCompare(b.value)) : null;
        if(sortedProgsData) this.internalState.setSelectedProgram(sortedProgsData[0]);
        return sortedProgsData && sortedProgsData.length ? sortedProgsData : null;
      }),
      catchError((error: Error | HttpErrorResponse) => {
        console.warn('Error Loading Org', error);
        return of(null);
      })
    );
  }

  getRegistrationsList(programId: string): Observable<NgxDropdownOption[] | null> {
    return this.registrationService.getRegistrations(programId).pipe(
      map((registrations: RegistrationResponse[]) => {
        const userPreference = this.internalState.userPreferenceSignal();
        if (!registrations || !registrations.length) return null;
        const regsData: NgxDropdownOption[] = registrations.map((registration: RegistrationResponse) => {
          const regsMeta: NgxDropdownOption = {
            value: this.getLocalisedLabel(registration.display_labels, userPreference?.apiLocale ?? '', 'en_US'),
            id: registration.id
          };
          return regsMeta;
        });
        const sortedRegsData = regsData.length ? regsData.sort((a, b) => a.value.localeCompare(b.value)) : null;
        if(sortedRegsData) this.internalState.setSelectedReg(sortedRegsData[0]);
        return sortedRegsData && sortedRegsData.length ? sortedRegsData : null;
      }),
      catchError((error: Error | HttpErrorResponse) => {
        console.warn('Error Loading Org', error);
        return of(null);
      })
    );
  }

  getRegistrationsListUsingSession(): Observable<NgxDropdownOption[] | null> {
    return this.registrationService.getRegistrationsUsingSession().pipe(
      map((registrations: RegistrationResponse[]) => {
        const userPreference = this.internalState.userPreferenceSignal();
        if (!registrations || !registrations.length) return null;
        const regsData: NgxDropdownOption[] = registrations.map((registration: RegistrationResponse) => {
          const regsMeta: NgxDropdownOption = {
            value: this.getLocalisedLabel(registration.display_labels, userPreference?.apiLocale ?? '', 'en_US'),
            id: registration.id
          };
          return regsMeta;
        });
        const sortedRegsData = regsData.length ? regsData.sort((a, b) => a.value.localeCompare(b.value)) : null;
        if(sortedRegsData) this.internalState.setSelectedReg(sortedRegsData[0]);
        return sortedRegsData && sortedRegsData.length ? sortedRegsData : null;
      }),
      catchError((error: Error | HttpErrorResponse) => {
        console.warn('Error Loading Org', error);
        return of(null);
      })
    );
  }

  getRegViewConfig(): Observable<RegConfig | null> {
    const selectedRegistrationId = this.selectedRegFormControl.value ? this.selectedRegFormControl.value.id : '';

    // Optionally, return early if selectedRegistrationId is empty
    if (!selectedRegistrationId) {
      return of(null);
    }

    const cachedObservable: Observable<RegConfig | null> | undefined = this.regViewConfigCache.get(selectedRegistrationId);

    if (cachedObservable) {
      return cachedObservable;
    }

    const vm: Observable<RegConfig | null> = this.registrationViewConfigService.getRegistrationViewConfig(selectedRegistrationId).pipe(
      map((regConfigResponse: RegistrationViewConfigResponse) => {
        if (!regConfigResponse) return null;
        this.isGroupedOffersSignal.set(regConfigResponse.operational_hours ? true : false);
        const userPreference = this.internalState.userPreferenceSignal();
        const transformedConfig: RegConfig = transformRegConfig(regConfigResponse, userPreference?.apiLocale ?? '');
        this.internalState.setRegViewConfigState(transformedConfig);
        return transformedConfig;
      }),
      shareReplay(1),
      catchError((error: Error | HttpErrorResponse) => {
        console.warn('Error Loading Org', error);
        this.regViewConfigCache.delete(selectedRegistrationId);
        return EMPTY;
      })
    );

    this.regViewConfigCache.set(selectedRegistrationId, vm);
    return vm;
  }


  getExportOffers(dateRange: ExportReportOutput): Observable<{ offers: OfferResponse[] | null, timeZone: string } | null> {
    return this.internalState.selectedRegViewConfigFilterState.pipe(
        switchMap((regConfig: RegConfig | null) => {

            if (!regConfig || !dateRange) return of(null);
            const tz = new Timezone(regConfig?.timeZone ?? 'UTC');
            const startDate: moment.Moment = moment(dateRange.startMomentDate);
            const endDate: moment.Moment = moment(dateRange.endMomentDate);
            const shortTz = tz.getOffset(startDate.format());

            const selectedDatesISO: DaterangeISO = {
              start:moment.utc(startDate.utcOffset(shortTz.stringOffset, true)).toISOString(),
              end: moment.utc(endDate.utcOffset(shortTz.stringOffset, true)).toISOString()
            }

            return forkJoin({
                offers: this.offerService.getOffers(regConfig.registrationId, selectedDatesISO.start, selectedDatesISO.end),
                timeZone: of(regConfig.timeZone)
            });
        })
    );
}

  getGroupedDataWithDV(): Observable<GroupedData | null> {
    const selectedDates = this.selectedDateFormControl.value ? this.selectedDateFormControl.value : null;
    return this.getRegViewConfig().pipe(
          switchMap((regConfig: RegConfig | null) => {
            if (!regConfig || !selectedDates) return EMPTY;
            const selectedDatesISO = this.dateService.getISODate(selectedDates, regConfig.timeZone);
            return this.daService.getDA(regConfig.registrationId, selectedDatesISO.start, selectedDatesISO.end).pipe(
              map((offers: DAResponse[] | null) => {

                const offerData: DataModel[] = this.dataModelService.getGridDataFromDA(
                  offers as DAResponse[],
                  regConfig,
                  selectedDatesISO.start,
                  selectedDatesISO.end
                );

                // Cast the start and end date to remove the error "No overload matches this call."
                const startdate: moment.Moment = moment(selectedDates.start as moment.Moment);
                const enddate: moment.Moment = moment(selectedDates.end as moment.Moment);

                const dayOfWeek: DayOfWeekModel = {
                  data: offerData,
                  timeZone: regConfig.timeZone,
                  startDate: startdate.utc(),
                  endDate: enddate.utc(),
                  intervalFrequency: regConfig.intervalFrequency,
                  tableType: TableDataTypes.DECLARED_AVAILABILITIES
                };

                const groupedData = this.ngxService.groupByDayOfWeek(
                  dayOfWeek.data,
                  dayOfWeek.timeZone,
                  dayOfWeek.tableType
                );
                return {
                  values: groupedData,
                  regConfig: regConfig,
                  selectedDateRange: selectedDates
                };
              })
            );
          })
        );
  }

  getGroupedDataWithOffers(): Observable<GroupedData | null> {
    const selectedDates = this.selectedDateFormControl.value ? this.selectedDateFormControl.value : null;
    if (!selectedDates) {
      return EMPTY;
    }
      return this.getRegViewConfig().pipe(
          switchMap((regConfig: RegConfig | null) => {
            if (!regConfig) return EMPTY;
            const selectedDatesISO = this.dateService.getISODate(
              selectedDates,
              regConfig.timeZone
            );

            const clear$ = this.offerService
              .getOffers(regConfig.registrationId, selectedDatesISO.start, selectedDatesISO.end)
              .pipe(
                map((offers: OfferResponse[] | null) => {
                  const offerData: DataModel[] = this.dataModelService.getGridDataFromOffers(
                    offers as OfferResponse[],
                    regConfig
                  );

                  const startDate = moment(selectedDates.start as moment.Moment).utc();
                  const endDate = moment(selectedDates.end as moment.Moment).utc();

                  const dayOfWeek: DayOfWeekModel = {
                    data: offerData,
                    timeZone: regConfig.timeZone,
                    startDate,
                    endDate,
                    intervalFrequency: regConfig.intervalFrequency,
                    tableType: regConfig.operational_hours ? TableDataTypes.CLEAR : TableDataTypes.OPEN
                  };

                  const groupedData = this.ngxService.groupByDayOfWeek(
                    dayOfWeek.data,
                    dayOfWeek.timeZone,
                    dayOfWeek.tableType
                  );

                  return {
                    values: groupedData,
                    regConfig,
                    selectedDateRange: selectedDates
                  };
                })
              );

            const open$ = regConfig.operational_hours
              ? this.offerService
                  .getGroupedOffers(regConfig.registrationId, selectedDatesISO.start, selectedDatesISO.end)
                  .pipe(
                    map((groups: OperationalGroup[] | null) => {
                      const groupedData = this.ngxService.groupByIntervals(
                        groups as OperationalGroup[],
                        regConfig.operational_hours as OperationalHours[],
                        TableDataTypes.OPEN,
                        'ON_PEAK',
                        'OFF_PEAK'
                      );
                      return {
                        values: groupedData,
                        regConfig,
                        selectedDateRange: selectedDates
                      };
                    })
                  )
              : clear$;

            return open$;;
          })
        );
  }

  getGroupedDataWithCleared(): Observable<GroupedData | null> {
    const selectedDates = this.selectedDateFormControl.value ? this.selectedDateFormControl.value : null;
    if (!selectedDates) EMPTY
      return this.getRegViewConfig().pipe(
          switchMap((regConfig: RegConfig | null) => {
            if (!regConfig) return EMPTY;

            const selectedDatesISO = this.dateService.getISODate(
              selectedDates,
              regConfig.timeZone
            );

            return this.offerService
              .getOffers(regConfig.registrationId, selectedDatesISO.start, selectedDatesISO.end)
              .pipe(
                map((offers: OfferResponse[] | null) => {
                  const offerData: DataModel[] = this.dataModelService.getGridDataFromOffers(
                    offers as OfferResponse[],
                    regConfig
                  );

                  const startDate = moment(selectedDates.start as moment.Moment).utc();
                  const endDate = moment(selectedDates.end as moment.Moment).utc();

                  const dayOfWeek: DayOfWeekModel = {
                    data: offerData,
                    timeZone: regConfig.timeZone,
                    startDate,
                    endDate,
                    intervalFrequency: regConfig.intervalFrequency,
                    tableType: TableDataTypes.CLEAR
                  };

                  const groupedData = this.ngxService.groupByDayOfWeek(
                    dayOfWeek.data,
                    dayOfWeek.timeZone,
                    dayOfWeek.tableType
                  );

                  return {
                    values: groupedData,
                    regConfig,
                    selectedDateRange: selectedDates
                  };
                })
              );
          })
        );
  }

  getGroupedDataWithSchedule(): Observable<GroupedData | null> {
    const selectedDates = this.selectedDateFormControl.value ? this.selectedDateFormControl.value : null;
    if (!selectedDates) return EMPTY;
    return this.getRegViewConfig().pipe(
          switchMap((regConfig: RegConfig | null) => {
            if (!regConfig || !regConfig.tab_display.display_schedule_tab) return EMPTY;
            return this.scheduleService.getSchedule(regConfig.registrationId).pipe(
              map((schedule: ScheduleResponse[]) => {
                const scheduleData: DataModel[] = this.dataModelService.getGridDataFromSchedule(schedule, regConfig);
                //TODO - remove this
                const scheduleFirstIndex: DataModel = scheduleData[0];
                const scheduleLastIndex: DataModel = scheduleData[1];

                const startdate: moment.Moment = moment(scheduleFirstIndex.offer_start_dttm_utc);
                const enddate: moment.Moment = moment(scheduleLastIndex.offer_end_dttm_utc);

                const dayOfWeek: DayOfWeekModel = {
                  data: scheduleData,
                  timeZone: regConfig.timeZone,
                  startDate: startdate.utc(),
                  endDate: enddate.utc(),
                  intervalFrequency: regConfig.intervalFrequency,
                  tableType: TableDataTypes.SCHEDULE
                };

                const groupedData = this.ngxService.groupByDayOfWeek(
                  dayOfWeek.data,
                  dayOfWeek.timeZone,
                  dayOfWeek.tableType
                );
                return {
                  values: groupedData,
                  regConfig: regConfig,
                  selectedDateRange: selectedDates ?? undefined
                };
              })
            );
          })
        );
  }

  getGroupedDataWithBaseline(): Observable<GroupedData | null> {
    const selectedDates = this.selectedDateFormControl.value ? this.selectedDateFormControl.value : null;
    if (!selectedDates) return of(null);  // Return immediately if no dates are selected

    return this.getRegViewConfig().pipe(
      switchMap((regConfig: RegConfig | null) => {
        if (!regConfig || !regConfig.tab_display.display_baselines_tab || !selectedDates) {
          return of(null);
        }
        const selectedDatesISO = this.dateService.getISODate(selectedDates, regConfig.timeZone);
        return this.baselineService.getBaselines(regConfig.registrationId, selectedDatesISO.start, selectedDatesISO.end).pipe(
          map((baseline: BaselineResponse[]) => {
            const baselineData: DataModel[] = this.dataModelService.getGridDataFromBaseline(baseline, regConfig);
            //TODO - remove this
            const baselineFirstIndex: DataModel = baselineData[0];
            const baselineLastIndex: DataModel = baselineData[1];

            const startdate: moment.Moment = moment(baselineFirstIndex.offer_start_dttm_utc);
            const enddate: moment.Moment = moment(baselineLastIndex.offer_end_dttm_utc);

            const dayOfWeek: DayOfWeekModel = {
              data: baselineData,
              timeZone: regConfig.timeZone,
              startDate: startdate.utc(),
              endDate: enddate.utc(),
              intervalFrequency: regConfig.intervalFrequency,
              tableType: TableDataTypes.BASELINE
            };

            const groupedData = this.ngxService.groupByDayOfWeek(
              dayOfWeek.data,
              dayOfWeek.timeZone,
              dayOfWeek.tableType
            );
            return {
              values: groupedData,
              regConfig: regConfig,
              selectedDateRange: selectedDates
            };
          }),
          catchError((error: HttpErrorResponse) => {
            console.error('FFF', error);

            return EMPTY;
          })
        );
      })
    );
  }

  postOffers(offers: OfferRequest[], regId: string): Observable<OfferResponse[] | null> {
    return this.offerService.putOffers(regId, offers).pipe(
      map((offers: OfferResponse[] | null) => {
        return offers as OfferResponse[];
      })
    );
  }

  putGroupedOffers(offers: GroupedOffersDataModel[], regId: string): Observable<OfferResponse[] | null> {
    return this.offerService.putGroupedOffers(regId, offers).pipe(
      map((offers: OfferResponse[] | null) => {
        return offers as OfferResponse[];
      })
    );
  }

  postDAOffers(offers: DARequest[], regId: string): Observable<DAResponse[] | null> {
    return this.daService.putDAOffers(regId, offers).pipe(
      map((offers: DAResponse[] | null) => {
        return offers as DAResponse[];
      })
    );
  }

  postSchedule(offers: ScheduleRequest[], regId: string, ispost: boolean): Observable<ScheduleResponse[] | null> {
    return !ispost
    ? this.scheduleService.putSchedule(regId, offers).pipe(
      map((offers: ScheduleResponse[]) => {
        return offers as ScheduleResponse[];
      })
    )
    : this.scheduleService.postSchedule(regId, offers).pipe(
      map((offers: ScheduleResponse[]) => {
        return offers as ScheduleResponse[];
      })
    )
  }

  postBaseline(baseline: BaselineRequest[], regId: string): Observable<BaselineResponse[] | null> {
    return this.baselineService.putBaselines(regId, baseline).pipe(
      map((_baseline: BaselineResponse[]) => {
        return _baseline as BaselineResponse[];
      })
    );
  }


  public refreshData(tabName: TableDataType): void {
    this.ngxService.clearUnSavedData();
    this.internalState.editMode$.next(false);
    switch(tabName) {
      case TableDataTypes.OPEN: return this.refreshOpenTabTrigger$.next();
      case TableDataTypes.SCHEDULE: return this.refreshScheduleTrigger$.next();
      case TableDataTypes.BASELINE: return this.refreshBaselineTrigger$.next();
      case TableDataTypes.DECLARED_AVAILABILITIES: return this.refreshDATrigger$.next();
    }
  }

  public cancelEditing(dayOfWeekData: DayOfWeekModel): NgxIntervalDataGridRowModel[] {
    this.ngxService.clearUnSavedData();
    return this.ngxService.groupByDayOfWeek(
      dayOfWeekData.data,
      dayOfWeekData.timeZone,
      dayOfWeekData.tableType
    )
  }

  public lookForDSTDuplication(unsavedOffers: DetailTableCellModel[], timeZone: string): DetailTableCellModel[] {
    const duplicateOffers: DetailTableCellModel[] = [];

            unsavedOffers.forEach((offer: DetailTableCellModel) => {
              if (this.ngxService.isDSTEnd(offer.start_date_time, timeZone)) {
                const duplicatedOffer: DetailTableCellModel = { ...offer };

                const startDate = new Date(duplicatedOffer.start_date_time);
                const endDate = new Date(duplicatedOffer.end_date_time);

                startDate.setUTCHours(startDate.getUTCHours() - 1);
                endDate.setUTCHours(endDate.getUTCHours() - 1);

                duplicatedOffer.start_date_time = startDate.toISOString();
                duplicatedOffer.end_date_time = endDate.toISOString();
                duplicateOffers.push(duplicatedOffer);
              }
            });

            // Add the duplicated offers to the original array or handle them separately as needed
            unsavedOffers.push(...duplicateOffers);
            return unsavedOffers;
  }

  private getLocalisedLabel(displayLabelMap: { [locale: string]: string }, apiLocale: string, fallbackLocale: string): string {
    if (displayLabelMap[apiLocale] !== undefined) {
      return displayLabelMap[apiLocale];
    } else if (displayLabelMap[fallbackLocale] !== undefined) {
      return displayLabelMap[fallbackLocale];
    } else if (displayLabelMap[Object.keys(displayLabelMap)[0]] !== undefined) {
      return displayLabelMap[Object.keys(displayLabelMap)[0]];
    } else {
      return 'Undefined';
    }
  }

}
