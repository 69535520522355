import { ChangeDetectionStrategy, Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateRange } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { DatePickerEnum, DatePickerType, NgxDateRangePickerComponent, NgxDateRangePickerOption, NgxDateRangePickerOutput } from 'ngx-date-range-picker';
import { Subscription } from 'rxjs';
import { ExportReportOutput, MinuteFrequencyOption } from '../../../shared/models';

@Component({
  selector: 'dr-customer-offers-ui-export-report',
  templateUrl: './export-report.component.html',
  styleUrls: ['./export-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportReportComponent implements OnDestroy {

  @ViewChild('endDatePicker', { static: true }) endDateInput!: NgxDateRangePickerComponent;

  protected error = '';
  protected invalidFormName: string | undefined;
  private minuteFrequency: MinuteFrequencyOption = 30;

  protected startHour12 = 12;
  protected startPeriod = 'am';
  protected startMinute = 0;

  protected endHour12 = 12;
  protected endPeriod = 'am'
  protected endMinute = 0;

  // Selected date range (for the mat-datepicker)
  protected startDate: FormControl<NgxDateRangePickerOutput | null>  = new FormControl(null);
  protected endDate: FormControl<NgxDateRangePickerOutput | null>  = new FormControl(null);

  // Selected date range (for the mat-calendar)
  protected selectedDateRange = new DateRange<Date | null>(null, null);

  // Min and max dates
  protected minStartDate!: Date | undefined;
  protected minEndDate!: Date | undefined;
  protected maxStartDate!: Date | undefined;
  protected maxEndDate!: Date | undefined;
  protected startDateFormControl: FormControl<NgxDateRangePickerOutput | null> = new FormControl(null);
  protected endDateFormControl: FormControl<NgxDateRangePickerOutput | null> = new FormControl(null);

  options: NgxDateRangePickerOption[] = [];
  date: Date | undefined;
  protected selectedType: DatePickerType = DatePickerEnum.DatePicker;
  private pickerDateChange$: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { minuteFrequency: number, minDate: moment.Moment, maxDate: moment.Moment}, public dialogRef: MatDialogRef<ExportReportComponent>, private translate: TranslateService) {
    // To limit the date range selection, pass a maxDate and a minDate.
    // The user can choose a range between any dates if there are no minDate and maxDate.
    this.initData(data);

    this.pickerDateChange$ = this.startDate.valueChanges.subscribe((selectedDate) => this.startDateChange(selectedDate));
    this.pickerDateChange$.add(
      this.endDate.valueChanges.subscribe((selectedDate) => this.endDateChange(selectedDate))
    );
  }

  ngOnDestroy(): void {
    this.pickerDateChange$.unsubscribe();
  }

  protected incrementHour(type: string): void {
    // Increment the hours by 1 and update the converted time
    if (type === 'start') {
      this.startHour12 < 12 ? this.startHour12++ : this.startHour12 = 1;
    } else if (type === 'end') {
      this.endHour12 < 12 ? this.endHour12++ : this.endHour12 = 1;
    }
  }

  protected decrementHour(type: string): void {
    // Decrement the hours by 1 and update the converted time
    if (type === 'start') {
      this.startHour12 > 1 ? this.startHour12-- : this.startHour12 = 12;
    } else if (type === 'end') {
      this.endHour12 > 1 ? this.endHour12-- : this.endHour12 = 12;
    }
  }

  protected incrementMinuteByFrequency(type: string): void {
    const maxMinute = 59;
    let newMinute = 0;

    if (type === 'start') {
      newMinute = this.startMinute + this.minuteFrequency;

      if (newMinute <= maxMinute) {
        this.startMinute = newMinute;
      } else {
        // Handle the case where the new minute exceeds 59
        this.startMinute = 0;
      }
    } else if (type === 'end') {
      newMinute = this.endMinute + this.minuteFrequency;

      if (newMinute <= maxMinute) {
        this.endMinute = newMinute;
      } else {
        // Handle the case where the new minute exceeds 59
        this.endMinute = 0;
      }
    }
  }

  protected decrementMinuteByFrequency(type: string): void {
    const minMinute = 0;
    let newMinute = 0;

    if (type === 'start') {
      newMinute = this.startMinute - this.minuteFrequency;
      if (newMinute >= minMinute) {
        this.startMinute = newMinute;
      } else {
        // Handle the case where the new minute goes below 0
        this.startMinute = 60 - this.minuteFrequency;
      }
    } else if (type === 'end') {
      newMinute = this.endMinute - this.minuteFrequency;
      if (newMinute >= minMinute) {
        this.endMinute = newMinute;
      } else {
        // Handle the case where the new minute goes below 0
        this.endMinute = 60 - this.minuteFrequency;
      }
    }
  }

  protected selectedDateRangeChange(date: Date): void {
    const selectedMoment = moment(date); // Convert selected date to Moment.js

    if (
      this.selectedDateRange &&
      this.selectedDateRange.start &&
      !this.selectedDateRange.end &&
      date >= this.selectedDateRange.start
    ) {
      this.endDate = new FormControl(
        { start: selectedMoment, end: selectedMoment },
      );
      this.renewSubscription();
      this.selectedDateRange = new DateRange(this.selectedDateRange.start, date);
    } else if (
      this.selectedDateRange &&
      this.selectedDateRange.end &&
      !this.selectedDateRange.start &&
      date <= this.selectedDateRange.end
    ) {
      this.startDate = new FormControl(
        { start: selectedMoment, end: selectedMoment },
      );
      this.renewSubscription();
      this.selectedDateRange = new DateRange(date, this.selectedDateRange.end);
    } else {
      this.startDate = new FormControl(
        { start: selectedMoment, end: selectedMoment },
      );
      this.endDate = new FormControl(null);

      this.renewSubscription();

      this.endDateInput.reset();
      this.selectedDateRange = new DateRange(date, null);
    }
  }

  protected endDateChange(d: NgxDateRangePickerOutput | null): void {
    const date = d?.start?.toDate();
    this.maxStartDate = date;
    const startDateValue = this.startDate?.value?.start;
    const startDateValueAsDate = startDateValue?.toDate();
    if (date && startDateValue) {
      if (date >= startDateValue.toDate()) {
        this.selectedDateRange = new DateRange(
          startDateValue.toDate(),
          date
        );
      } else if (this.selectedDateRange && this.selectedDateRange.end) {
        const endDateValue = this.endDate.value?.start;
        this.endDate.setValue({start: endDateValue, end: endDateValue}, {emitEvent: false});
      }
    } else if (date && !startDateValue) {
      this.selectedDateRange = new DateRange(
        null,
        date
      );
    } else if (startDateValueAsDate) {
      this.selectedDateRange = new DateRange(
        startDateValueAsDate,
        null
      );
    }
  }

  protected startDateChange(d: NgxDateRangePickerOutput | null): void {
    const date = d?.start?.toDate();
    this.startDate?.setValue(d, {emitEvent: false});
    this.minEndDate = date;
    const endDateValue = this.endDate?.value?.start;
    if (date && endDateValue) {
      if (date <= endDateValue.toDate()) {
        this.selectedDateRange = new DateRange(
          date,
          endDateValue.toDate(),
        );
        return;
      }
      if (this.selectedDateRange && this.selectedDateRange.start) {
        const startDateValue = moment(this.selectedDateRange.start);
        this.startDate.setValue({start: startDateValue, end: startDateValue}, {emitEvent: false});
        return;
      }
      return;
    } if (date && !endDateValue) {
      this.selectedDateRange = new DateRange(
        date,
        null
      );
      return;
    }
    this.selectedDateRange = new DateRange(
      null,
      null
    );
    if (d?.start && d.end) {
      this.endDateInput.reset();
    }
  }


  protected apply(): void {
    if (!this.checkIfDataIsValid()) {
      return;
    }
    const output: ExportReportOutput = {
      startMomentDate: this.startDate.value?.start ? this.createMomentWithDateAndTime(this.startDate.value.start.toDate(), this.startHour12, this.startMinute, this.startPeriod) : undefined,
      endMomentDate: this.endDate.value?.start ? this.createMomentWithDateAndTime(this.endDate.value.start.toDate(), this.endHour12, this.endMinute, this.endPeriod) : undefined
    }
    this.dialogRef.close(output)
  }

  private initData(data: { minuteFrequency: number, minDate: moment.Moment, maxDate: moment.Moment }): void {
    // Check if the provided value is type MinuteFrecuencyOption
    if (this.isMinuteFrecuencyOption(data.minuteFrequency)) {
      this.minuteFrequency = data.minuteFrequency as MinuteFrequencyOption;
    } else {
      // If not, set default and throw an warning
      console.warn('The minuteFrequency should be one of the following numbers: 0, 5, 10, 15, 30. The default of 30 mins as frequency will be used.')
      this.minuteFrequency = 30;
    }

    // To limit the date range selection, pass a maxDate and a minDate.
    // The user can choose a range between any dates if there are no minDate and maxDate.
    if (data && moment.isMoment(data.minDate) && moment.isMoment(data.maxDate)) {
      this.minStartDate = data.minDate.toDate();
      this.minEndDate = data.minDate.toDate();
      this.maxStartDate = data.maxDate.toDate();
      this.maxEndDate = data.maxDate.toDate();
    }
  }

  private checkIfDataIsValid(): boolean {
    if (!this.startDate.value || !this.endDate.value) {
      this.invalidFormName = 'startEndDate';
      this.error = this.translate.instant('COM.ERRORS.EXPORT_REPORT.EMPTY_DATES');
      return false;
    } else if (this.endDate.value?.start?.isBefore(this.startDate.value?.start)) {
      this.invalidFormName = 'startEndDate';
      this.error = this.translate.instant('COM.ERRORS.EXPORT_REPORT.END_DATE');
      return false;
    } else if (!this.endDateTimeIsAfterStartDateTime()) {
      this.invalidFormName = 'startEndTime';
      this.error = this.translate.instant('COM.ERRORS.EXPORT_REPORT.END_TIME');
      return false;
    } else {
      this.error = this.translate.instant('COM.ERRORS.GENERIC_ERROR');
      this.invalidFormName = undefined;
      return true;
    }
  }

  private createMomentWithDateAndTime(
    date: Date,
    hour12: number,
    minutes: number,
    period: string
  ): moment.Moment | null {
    // Validate input values
    if (
      isNaN(date.getTime()) || // Check if the date is valid
      hour12 < 1 || hour12 > 12 ||   // Check if the hour is in the valid 1-12 range
      minutes < 0 || minutes > 59 || // Check if minutes are in the valid 0-59 range
      (period !== 'am' && period !== 'pm') // Check if period is 'AM' or 'PM'
    ) {
      console.log('Invalid input values.');
      return null;
    }

    // Extract the day, month, and year from the input date
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    // Convert 12-hour format to 24-hour format
    let hour24 = hour12;
    if (period === 'pm' && hour12 !== 12) {
      hour24 += 12;
    } else if (period === 'am' && hour12 === 12) {
      hour24 = 0;
    }

    // Create a Moment.js object
    return moment({
      year: year,
      month: month,
      day: day,
      hour: hour24,
      minute: minutes,
      second: 0, // Set seconds to 0 if not needed
    });
  }

  private endDateTimeIsAfterStartDateTime(): boolean {
    if (!this.endDate.value?.start || !this.startDate.value?.start) {
      return false;
    }

    const start = this.createMomentWithDateAndTime(this.startDate.value.start.toDate(), this.startHour12, this.startMinute, this.startPeriod);
    const end = this.createMomentWithDateAndTime(this.endDate.value.start.toDate(), this.endHour12, this.endMinute, this.endPeriod);

    return (end && start) ? end.isAfter(start) : false;
  }

  private isMinuteFrecuencyOption(myVar: number): myVar is MinuteFrequencyOption {
    return myVar === 0 || myVar === 5 || myVar === 10 || myVar === 15 || myVar === 30;
  }

  private renewSubscription() {
    this.pickerDateChange$.unsubscribe();

    this.pickerDateChange$ = this.startDate.valueChanges.subscribe((selectedDate) => this.startDateChange(selectedDate));
    this.pickerDateChange$.add(
      this.endDate.valueChanges.subscribe((selectedDate) => this.endDateChange(selectedDate)),
    );
  }
}
