<dr-customer-offers-ui-global-alert></dr-customer-offers-ui-global-alert>
<global-nav [navUrl]="navUrl" [appLabel]="appTitleValue.value | translate"></global-nav>
<dr-customer-offers-ui-headboard [showEditButton]="selectedTabOption && selectedTabOption.showEditButton ? selectedTabOption.showEditButton : false"></dr-customer-offers-ui-headboard>
<div class="com-container">
  <dr-customer-offers-ui-filters></dr-customer-offers-ui-filters>
      <div *ngIf="(selectedRegFormControl$ | async)?.id; else errorMessage">
        <div *ngIf="{ editMode: (editMode$ | async)} as vm" class="com-container_header">
          <div class="com-container_header--tabs">
            <dr-customer-offers-ui-tabs (tabChange)="tabChanged($event)"></dr-customer-offers-ui-tabs>
          </div>
          <div class="com-container_header--export">
            <ng-container *ngIf="selectedTabOption.exportFunctionality && !vm.editMode">
              <button mat-stroked-button color="primary" class="download-btn"
              (click)="openExportFunctionalityDialog()">{{ 'COM.BUTTONS.EXPORT_REPORT' | translate }}
              <mat-icon class="lh-9">file_download</mat-icon></button>
            </ng-container>
            <ng-container *ngIf="selectedTabOption.bulkFunctionality && vm.editMode && !isGroupedOffersSignal()">
              <dr-customer-offers-ui-bulk-offers
                [minDate]="minDate"
                [maxDate]="maxDate"
                [selectedTabOption]="selectedTabOption"
              ></dr-customer-offers-ui-bulk-offers>
            </ng-container>
          </div>
        </div>
        <div class="mt-5">
          <router-outlet></router-outlet>
        </div>
      </div>
      <ng-template #errorMessage>
        <dr-customer-offers-ui-no-data [title]="errorMessageTitle" [message]="message"></dr-customer-offers-ui-no-data>
      </ng-template>
</div>
<ngx-global-contact-footer></ngx-global-contact-footer>
