<ng-container *ngIf="isLoaded(); else loadingTemplate">
<div class="com-table" *ngIf="{ editMode: (editMode$ | async), offerGroupData: (offerDataWithReg) } as vm">
  <div class="com-table_container">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" [class.mat-mdc-table--spacing]="!vm.editMode" multiTemplateDataRows>
      <ng-container matColumnDef="offer">
        <th mat-header-cell *matHeaderCellDef class="com-table_container--header-offer-row">{{'COM.LABELS.OFFER_NUM' | translate}}</th>
        <td
          mat-cell
          *matCellDef="let element"
          class="com-table_container--offer-column"
          [class.com-table_container--time-column-first-child]="vm.editMode"
        >
          <b *ngIf="!element?.groupedOffersIntervals">{{ element.index }}</b>
        </td>
      </ng-container>

      <ng-container matColumnDef="time_period">
        <th mat-header-cell *matHeaderCellDef class="com-table_container--header-row">{{ 'COM.LABELS.TIME_FRAME' | translate }}</th>
        <td
          mat-cell
          *matCellDef="let element"
          class="com-table_container--time-column"
          [class.com-table_container--time-column-first-child]="vm.editMode"
        >
          <b *ngIf="!element?.groupedOffersIntervals; else groupedOffers">{{ { inputTime: element.timePeriodKey, timeGap: (offerDataWithReg?.regConfig)!.intervalFrequency } | mapTime }}</b>
          <ng-template #groupedOffers>
            <b [innerHTML]="element.timeRangeText"></b>
          </ng-template>
          ({{ timezoneAbbr }})
        </td>
      </ng-container>

      <ng-container *ngFor="let column of columns; let i = index" [matColumnDef]="column">
        <th mat-header-cell *matHeaderCellDef class="com-table_container--header-row">
          <ng-container *ngIf="vm.editMode && !dataSource.data[0]?.groupedOffersIntervals; else headerNonEditMode">
            <mat-checkbox
              color="primary"
              class="checkbox-container--readonly checkbox-container--header"
              (change)="applyBulkOptout(column, $event.checked)"
              >{{ column | weekDay }} {{ dates[i] | suffixDate }}</mat-checkbox
            >
          </ng-container>
          <ng-template #headerNonEditMode> {{ column | weekDay }} {{ dates[i] | suffixDate }} </ng-template>
        </th>
        <td mat-cell *matCellDef="let element" class="com-table_container--columns" [class.com-table_container--columns-last-child]="vm.editMode">
          <div [class.com-table_container--columns-readonly]="!vm.editMode" [class.com-table_container--columns-readonly-last]="i === 6">
            <!-- Readonly mode -->
            <ng-container *ngIf="!vm.editMode; else editModeRef">
              <div class="td-cell_readonly">
                <div
                  class="td-cell_readonly-container"
                  [class.td-cell_readonly-container--gate-closed]="element[columns[i]].gate_closed && !element[columns[i]].is_emergency"
                >
                  <span class="td-cell_readonly-body">
                    <span class="flex">
                      <dr-customer-offers-ui-icons
                        *ngIf="element[columns[i]].gate_closed && !element[columns[i]].is_emergency"
                        [state]="UIState.GateClosed"
                        name="lock_outlined"
                      ></dr-customer-offers-ui-icons>
                      <dr-customer-offers-ui-icons *ngIf="element[columns[i]].opt_out" name="logout_outlined"></dr-customer-offers-ui-icons>
                      <dr-customer-offers-ui-icons *ngIf="element[columns[i]].is_dst_add_hr || !element[columns[i]].start_date_time" [isSVG]="true" svgName="sunny.svg"></dr-customer-offers-ui-icons>
                      <dr-customer-offers-ui-icons *ngIf="element[columns[i]].is_emergency" [isSVG]="true" svgName="warning.svg"></dr-customer-offers-ui-icons>
                      <dr-customer-offers-ui-icons *ngIf="element[columns[i]].is_holiday" [isSVG]="true" svgName="beach_access.svg"></dr-customer-offers-ui-icons>
                    </span>
                  </span>
                  <span
                    class="td-cell_readonly-body"
                    [class.td-cell_readonly-body--gate-closed]="element[columns[i]].gate_closed && !element[columns[i]].is_emergency"
                    >{{ element[columns[i]].value }}</span
                  >
                </div>
              </div>
            </ng-container>
            <!-- /Readonly mode -->
            <!-- Edit Mode -->
            <ng-template #editModeRef>
              <ng-container *ngIf="element[columns[i]].gate_closed && !element[columns[i]].is_emergency; else editValues">
                <div class="td-cell--align">
                  <div class="td-cell_readonly">
                    <div class="td-cell_readonly-container" [class.td-cell_readonly-container--gate-closed]="element[columns[i]].gate_closed">
                      <span class="td-cell_readonly-body">
                        <span class="flex">
                          <dr-customer-offers-ui-icons
                          *ngIf="element[columns[i]].gate_closed"
                          [state]="UIState.GateClosed"
                          name="lock_outlined"
                        ></dr-customer-offers-ui-icons>
                        <dr-customer-offers-ui-icons
                          *ngIf="element[columns[i]].is_holiday"
                          [isSVG]="true"
                          svgName="beach_access.svg"
                        ></dr-customer-offers-ui-icons>
                        <dr-customer-offers-ui-icons *ngIf="element[columns[i]].is_dst_add_hr" [isSVG]="true" svgName="sunny.svg"></dr-customer-offers-ui-icons>
                        </span>
                      </span>
                      <span
                        class="td-cell_readonly-body"
                        [class.td-cell_readonly-body--gate-closed]="element[columns[i]].gate_closed && !element[columns[i]].is_emergency"
                        >{{ element[columns[i]].value }}</span
                      >
                    </div>
                  </div>
                  <div class="checkbox-container_readonly">
                    <mat-checkbox
                      color="primary"
                      class="checkbox-container--align checkbox-container--readonly"
                      [ngModel]="element[column].opt_out"
                      [disabled]="true"
                      >{{ 'COM.CHECKBOX.OPT_OUT' | translate }}</mat-checkbox
                    >
                  </div>
                </div>
              </ng-container>

              <ng-template #editValues>
                <div class="td-cell--align">
                  <div class="td-cell">
                    <div class="td-cell_custom-input">
                      <div class="td-cell_icons">
                        <dr-customer-offers-ui-icons
                          *ngIf="element[columns[i]].is_emergency"
                          [isSVG]="true"
                          svgName="warning.svg"
                        ></dr-customer-offers-ui-icons>
                        <dr-customer-offers-ui-icons
                          *ngIf="element[columns[i]].is_holiday"
                          [isSVG]="true"
                          svgName="beach_access.svg"
                        ></dr-customer-offers-ui-icons>
                        <dr-customer-offers-ui-icons *ngIf="element[columns[i]].is_dst_add_hr || !element[columns[i]].start_date_time" [isSVG]="true" svgName="sunny.svg"></dr-customer-offers-ui-icons>
                      </div>
                      <input
                        class="td-cell_input"
                        [class.invalid]="element[columns[i]].invalid_value"
                        type="number"
                        [value]="element[columns[i]].value"
                        (blur)="inputToCellValue(element, $event, column)"
                        (wheel)="$event.preventDefault()"
                        validateNumber
                        [max]="offerDataWithReg?.regConfig?.maximumValue ?? 0"
                        [min]="offerDataWithReg?.regConfig?.minimumValue ?? 0"
                        [untouchedData]="offerDataWithReg?.values!"
                        [timePeriodKey]="element.timePeriodKey"
                        [week]="column"
                        [disabled]="!element[columns[i]].start_date_time || !offerDataWithReg?.regConfig?.gateRules?.post_gate_edits?.allow_offer_edit"
                      />
                    </div>
                  </div>

                  <div class="checkbox-container_readonly">
                    <mat-checkbox
                      color="primary"
                      class="checkbox-container--align checkbox-container--readonly"
                      [ngModel]="element[column].opt_out"
                      (change)="inputToCellOptOut(element, $event.checked, column)"
                      [disabled]="!element[columns[i]].start_date_time"
                      >{{ 'COM.CHECKBOX.OPT_OUT' | translate }}</mat-checkbox
                    >
                  </div>
                </div>
              </ng-template>
            </ng-template>
            <!-- /Edit Mode -->
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div
            [class.table-element-detail--padding]="vm.editMode"
            class="table-element-detail"
            [@detailExpand]="vm.editMode ? 'expanded' : 'collapsed'"
          >
            <div class="expand-container"></div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-header-row"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" class="table-element-row" [class.table-expanded-row]="vm.editMode"></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
    </table>
  </div>

  <div class="com-table_footer">
    <div class="com-table_footer-icon">
      <dr-customer-offers-ui-icons name="lock_outlined"></dr-customer-offers-ui-icons>
      <span>{{ 'COM.LEGEND.GATE_CLOSED' | translate }}</span>
    </div>
    <div class="com-table_footer-icon">
      <dr-customer-offers-ui-icons name="logout_outlined"></dr-customer-offers-ui-icons>
      <span>{{ 'COM.LEGEND.OPT_OUT' | translate }}</span>
    </div>
    <div class="com-table_footer-icon">
      <dr-customer-offers-ui-icons [isSVG]="true" svgName="beach_access.svg"></dr-customer-offers-ui-icons>
      <span>{{ 'COM.LEGEND.HOLIDAY' | translate }}</span>
    </div>
    <div class="com-table_footer-icon">
      <dr-customer-offers-ui-icons [isSVG]="true" svgName="warning.svg"></dr-customer-offers-ui-icons>
      <span>{{ 'COM.LEGEND.EDIT_INSIDE_GC' | translate }}</span>
    </div>
    <div class="com-table_footer-icon">
      <dr-customer-offers-ui-icons [isSVG]="true" svgName="sunny.svg"></dr-customer-offers-ui-icons>
      <span>{{ 'COM.LEGEND.DAYLIGHT_SAVINGS' | translate }}</span>
    </div>
  </div>
</div>
</ng-container>

<ng-template #loadingTemplate>
  <div class="loading-page">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

