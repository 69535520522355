<ng-container *ngIf="isLoaded(); else loadingTemplate">
<div class="com-table" *ngIf="{ editMode: editMode$ | async } as vm">
  <div class="com-table_container">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" [class.mat-mdc-table--spacing]="!vm.editMode" multiTemplateDataRows>

      <ng-container matColumnDef="offer">
        <th mat-header-cell *matHeaderCellDef class="com-table_container--header-offer-row">{{ 'COM.LABELS.OFFER_NUM' | translate}}</th>
        <td mat-cell *matCellDef="let element" class="com-table_container--offer-column">
          <b>{{ element.index }}</b>
        </td>
      </ng-container>

      <ng-container matColumnDef="time_period">
        <th mat-header-cell *matHeaderCellDef class="com-table_container--header-row">{{'COM.LABELS.TIME_FRAME' | translate}}</th>
        <td mat-cell *matCellDef="let element" class="com-table_container--time-column" [class.com-table_container--time-column-first-child]="vm.editMode">
          <b>{{ {inputTime: element.timePeriodKey, timeGap: scheduleDataWithReg?.regConfig!.intervalFrequency} | mapTime }}</b>
          ({{ timezoneAbbr }})
        </td>
      </ng-container>

      <ng-container *ngFor="let column of columns; let i = index" [matColumnDef]="column">
        <th mat-header-cell *matHeaderCellDef class="com-table_container--header-row">
          <ng-container *ngIf="vm.editMode; else readonlyHeader">
            <mat-checkbox
              color="primary"
              class="checkbox-container--readonly checkbox-container--header"
              (change)="applyBulkOptout(column, $event.checked)"
              >{{ column | weekDay }}</mat-checkbox
            >
          </ng-container>

          <ng-template #readonlyHeader>
            {{ column | weekDay }}
          </ng-template>
        </th>
          <td mat-cell *matCellDef="let element" class="com-table_container--columns" [class.com-table_container--columns-last-child]="vm.editMode">
            <div [class.com-table_container--columns-readonly]="!vm.editMode" [class.com-table_container--columns-readonly-last]="i === 6">
              <!-- Readonly mode -->
              <ng-container *ngIf="!vm.editMode; else editModeRef">
                <div class="td-cell_readonly">
                  <div class="td-cell_readonly-container">
                    <span class="td-cell_readonly-body">
                      <dr-customer-offers-ui-icons *ngIf="element[columns[i]].opt_out" name="logout_outlined"></dr-customer-offers-ui-icons>
                    </span>
                    <span class="td-cell_readonly-body">{{element[columns[i]].value }}</span>
                  </div>
                </div>
              </ng-container>
              <!-- /Readonly mode -->
              <!-- Edit Mode -->
              <ng-template #editModeRef>
                <ng-container>
                  <div class="td-cell--align">
                    <div class="td-cell">
                      <div class="td-cell_custom-input">
                        <input
                        class="td-cell_input"
                        type="number"
                        (wheel)="$event.preventDefault()"
                        [value]="element[columns[i]].value"
                        (keyup)="inputToCellValue(element, $event, column)"
                        validateNumber
                        [max]="scheduleDataWithReg?.regConfig?.maximumValue ?? 0"
                        [min]="scheduleDataWithReg?.regConfig?.minimumValue ?? 0"
                        [untouchedData]="scheduleDataWithReg?.values!"
                        [timePeriodKey]="element.timePeriodKey"
                        [week]="column"
                      />
                      </div>
                    </div>

                    <div class="checkbox-container">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-container--align checkbox-container--readonly"
                        [ngModel]="element[column].opt_out"
                        (change)="inputToCellOptOut(element, $event.checked, column)"
                        >{{'COM.CHECKBOX.OPT_OUT' | translate}}</mat-checkbox>
                    </div>
                  </div>
                </ng-container>
              </ng-template>
               <!-- /Edit Mode -->
            </div>
          </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div [class.table-element-detail--padding]="vm.editMode" class="table-element-detail"
               [@detailExpand]="vm.editMode ? 'expanded' : 'collapsed'">
               <div class="expand-container"></div>
          </div>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-header-row"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-element-row" [class.table-expanded-row]="vm.editMode"></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
    </table>
  </div>

  <div class="com-table_footer">
    <div class="com-table_footer-icon">
      <dr-customer-offers-ui-icons name="logout_outlined"></dr-customer-offers-ui-icons>
      <span>{{'COM.LEGEND.OPT_OUT' | translate}}</span>
    </div>
  </div>
</div>
</ng-container>

<ng-template #loadingTemplate>
  <div class="loading-page">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
