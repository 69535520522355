import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BulkType, Week } from 'ngx-interval-data-grid';
import { take } from 'rxjs';
import { BulkInput, InputTypeEnum, MinuteFrequencyOption, PeriodOption, PeriodOptionEnum, PeriodOptionValue, RegConfig, TabOption, TabOptionsEnum } from '../../shared/models';
import { DataViewModelService } from '../../shared/services/data-vm.service';
import { InternalService } from '../../shared/services/internal.service';
import { MixPanelService } from '../../shared/services/mixpanel.service';
import { BulkSelectionComponent } from '../dialogs/bulk-selection/bulk-selection.component';

@Component({
  selector: 'dr-customer-offers-ui-bulk-offers',
  templateUrl: './bulk-offers.component.html',
  styleUrls: ['./bulk-offers.component.scss'],
})
export class BulkOffersComponent implements OnInit {

  @Input() maxDate!: moment.Moment;
  @Input() minDate!: moment.Moment;
  @Input() selectedTabOption!: TabOption;
  regConfig!: RegConfig | null;
  minBulkValue!: number;
  maxBulkValue!: number;
  minuteFrequency: MinuteFrequencyOption | undefined;

  protected error = '';
  protected TabOptionsEnum = TabOptionsEnum;

  protected periodOptions: PeriodOption[] = [
    { value: PeriodOptionEnum.Week, label: this.translate.instant('COM.DIALOGS.BULK.ENTIRE_WEEK'), hidden: false },
    { value: PeriodOptionEnum.Custom, label: this.translate.instant('COM.DIALOGS.BULK.CUSTOM_RANGE'), hidden: false },
    { value: PeriodOptionEnum.Sunday, label: this.translate.instant('COM.LABELS.SUNDAY'), hidden: false },
    { value: PeriodOptionEnum.Monday, label: this.translate.instant('COM.LABELS.MONDAY'), hidden: false },
    { value: PeriodOptionEnum.Tuesday, label: this.translate.instant('COM.LABELS.TUESDAY'), hidden: false },
    { value: PeriodOptionEnum.Wednesday, label: this.translate.instant('COM.LABELS.WEDNESDAY'), hidden: false },
    { value: PeriodOptionEnum.Thursday, label: this.translate.instant('COM.LABELS.THURSDAY'), hidden: false },
    { value: PeriodOptionEnum.Friday, label: this.translate.instant('COM.LABELS.FRIDAY'), hidden: false },
    { value: PeriodOptionEnum.Saturday, label: this.translate.instant('COM.LABELS.SATURDAY'), hidden: false }
  ];
  protected selectedPeriod = this.periodOptions[0].value;
  protected bulkValue!: number | undefined;

  constructor(
    public dialog: MatDialog,
    private internalService: InternalService,
    private translate: TranslateService,
    private mixPanelService: MixPanelService,
    private datavmService: DataViewModelService) {
      this.datavmService.getRegViewConfig().pipe(take(1)).subscribe((regConfig) => {
        this.regConfig = regConfig;
        this.minBulkValue = regConfig!.minimumValue;
        this.maxBulkValue = regConfig!.maximumValue;
        this.minuteFrequency = regConfig?.intervalFrequency;
    })
  }

  ngOnInit(): void {
    const minuteFrequency = 1440 as MinuteFrequencyOption;
    if(this.minuteFrequency === minuteFrequency) {
      const periodIndex = this.periodOptions.findIndex(period => period.value == PeriodOptionEnum.Custom);
      this.periodOptions[periodIndex].hidden = true;
      this.periodOptions = this.periodOptions.filter((period) => period.hidden !== true);
    }
  }

  protected optionChange(value: PeriodOptionValue): void {
    if (value === PeriodOptionEnum.Custom) {
      this.bulkValue = undefined;
      this.openDialog();
    }
  }

  private openDialog(): void {
    const dialogRef = this.dialog.open(BulkSelectionComponent, { data: { minuteFrequency: this.minuteFrequency, maxBulkValue: this.maxBulkValue, minBulkValue: this.minBulkValue, minDate: this.minDate, maxDate: this.maxDate, selectedTabOption: this.selectedTabOption, regConfig: this.regConfig }, width: '56.6rem', disableClose: false, hasBackdrop: true });

    dialogRef.afterClosed().subscribe((bulkOfferOutput: BulkInput) => {
      if (bulkOfferOutput) {
        console.error('bulkOfferOutput', bulkOfferOutput)
      }
      this.selectedPeriod = this.periodOptions[0].value;
      this.mixPanelService.clickBulkDay(this.selectedPeriod);
    });
  }

  protected apply(): void {
    if (this.bulkValue !== null || this.bulkValue !== undefined || this.bulkValue >= this.minBulkValue || this.bulkValue <= this.maxBulkValue) {
      this.mixPanelService.clickBulkApply();
      const output: BulkInput = {
        bulkType: this.selectedPeriod === 'Week' ? BulkType.WEEK : BulkType.DAY,
        inputType: InputTypeEnum.VALUE,
        value: this.bulkValue,
        week: this.selectedPeriod !== 'Week' && this.selectedPeriod !== 'Custom' ? this.selectedPeriod as Week : undefined,
        startDateAndTime: undefined,
        endDateAndTime: undefined
      }
      this.internalService.setBulkInputs(output);
    } else {
      this.error = this.translate.instant('COM.ERRORS.BULK_SELECTION.INVALID_VALUE') + this.maxBulkValue;
    }
  }
}
