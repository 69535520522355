import { state, style, trigger } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, OnDestroy, OnInit, signal } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { NgxIntervalDataGridRowModel, WeekDays } from 'ngx-interval-data-grid';
import { catchError, EMPTY, filter, first, Subscription, tap } from 'rxjs';
import { GroupedData } from '../../../shared/models';
import { UIState } from '../../../shared/models/utility';
import { DataViewModelService } from '../../../shared/services/data-vm.service';
import { MixPanelService } from '../../../shared/services/mixpanel.service';

@Component({
  selector: 'dr-customer-offers-ui-cleared-table',
  templateUrl: './cleared-tab-table.component.html',
  styleUrls: ['./cleared-tab-table.component.scss'],
  animations: [trigger('detailExpand', [state('collapsed', style({ height: '0px', minHeight: '0' })), state('expanded', style({ height: '*' }))])],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClearedTabTableComponent implements OnInit, OnDestroy {
   // Signal to hold the grouped data; initially null indicates "not loaded"
  groupedDataSignal = signal<GroupedData | null>(null);
   // Computed signal: data is loaded when groupedDataSignal is not null
  isLoaded = computed(() => this.groupedDataSignal() !== null);

  subs = new Subscription();
  UIState = UIState;
  public dates: number[] = [];
  public timezoneAbbr!: string;
  protected clearedDataWithReg: GroupedData | null = null;

  // Define column headers and fields
  columns: string[] = WeekDays;
  displayedColumns: string[] = ['offer', 'time_period', ...this.columns];
  serverData: NgxIntervalDataGridRowModel[] = [];
  public dataSource = new MatTableDataSource<NgxIntervalDataGridRowModel>(this.serverData);
  public hasClearedOffers = false;

  protected expandedRow!: NgxIntervalDataGridRowModel | null;

  constructor(
    private mixPanelService: MixPanelService,
    private dataVMService: DataViewModelService,
    private cdr: ChangeDetectorRef,
    private router: Router) {}

  ngOnInit(): void {
    this.mixPanelService.viewTab('Cleared');

    this.loadData();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      first(),
    ).subscribe(() => {
      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private loadData(): void {
    this.groupedDataSignal.set(null);
    this.subs.add(
      this.dataVMService.getGroupedDataWithCleared().pipe(
        tap((value: GroupedData | null) => {
          if (value) {
            this.clearedDataWithReg = value;
            const timeZone = value.regConfig ? value.regConfig.timeZone : 'UTC';
            this.timezoneAbbr = moment.tz(timeZone).zoneName();
            this.dataSource.data = value.values ?? [];
            this.hasClearedOffers = this.dataSource.data.some(
              element => element.clearedOffers && element.clearedOffers.length > 0
            );
            if (this.hasClearedOffers) {
              this.displayedColumns.push('right_actions');
            }
            const startDate = value.selectedDateRange?.start
              ? moment.tz(value.selectedDateRange.start.format('YYYY-MM-DD HH:mm:ss'), timeZone)
              : moment.tz(new Date(), timeZone).startOf('day');
            this.dates = [];
            for (let i = 0; i < 7; i++) {
              const newDate = startDate.clone().add(i, 'days');
              this.dates.push(newDate.date());
            }
            // Set the grouped data signal (now data is loaded)
            this.groupedDataSignal.set(value);
          }
          this.cdr.detectChanges();
        }),
        catchError((error: HttpErrorResponse) => {
          this.groupedDataSignal.set(null);
          console.error('Error:', error);
          return EMPTY;
        })
      ).subscribe()
    );
  }
}
