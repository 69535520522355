import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, OnDestroy, OnInit, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationEnd, Router } from '@angular/router';
import { BaselineRequest } from '@dr-customer-offers-ui/lib-interfaces';
import { BulkType, DataModel, DetailTableCellModel, NgxIntervalDataGridRowModel, NgxIntervalDataGridService, Week, WeekDays } from '@ngx-interval-data-grid';
import * as moment from 'moment';
import { TableDataTypes } from 'ngx-interval-data-grid';
import { catchError, EMPTY, filter, first, Observable, Subscription, tap } from 'rxjs';
import { BulkInput, GroupedData, HeaderButtonTypeEnum, HeaderButtpnType, InputTypeEnum } from '../../../shared/models';
import { UIState } from '../../../shared/models/utility';
import { DataModelService } from '../../../shared/services/data-model.service';
import { DataViewModelService } from '../../../shared/services/data-vm.service';
import { InternalService } from '../../../shared/services/internal.service';
import { MessageService } from '../../../shared/services/message.service';
import { MixPanelService } from '../../../shared/services/mixpanel.service';

@Component({
  selector: 'dr-customer-offers-ui-baselines-table',
  templateUrl: './baselines-tab-table.component.html',
  styleUrls: ['./baselines-tab-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('150ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaselinesTabTableComponent implements OnInit, OnDestroy {
  // Signal to hold the grouped data; initially null indicates "not loaded"
  groupedDataSignal = signal<GroupedData | null>(null);
  // Computed signal: data is loaded when groupedDataSignal is not null
  isLoaded = computed(() => this.groupedDataSignal() !== null);

  subs = new Subscription();
  UIState = UIState;
  protected baselinesDataWithReg: GroupedData | null = null;
  public editMode$: Observable<boolean> = this.internalService.editMode$;
  public bindingValue!: number;
  private onClick$: Observable<HeaderButtpnType | null> = this.internalService.getOnChange$;

  protected dates: number[] = [];
  public timezoneAbbr!: string;

  // Define column headers and fields
  columns: string[] = WeekDays;
  displayedColumns: string[] = ['offer', 'time_period', ...this.columns];
  serverData!: NgxIntervalDataGridRowModel[];
  public dataSource = new MatTableDataSource<NgxIntervalDataGridRowModel>(this.serverData);

  constructor(
    private dataModelService: DataModelService,
    private dataVMService: DataViewModelService,
    private ngxService: NgxIntervalDataGridService,
    private internalService: InternalService,
    private cdr: ChangeDetectorRef,
    private mixPanelService: MixPanelService,
    public dialog: MatDialog,
    private messageService: MessageService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadData();
    this.subs.add(this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      first(),
    ).subscribe(() => {
      this.loadData();
    }));

    this.mixPanelService.viewTab('Baselines');
    this.applyBulk();
    this.subs.add(this.onClick$.subscribe((o) => {
      if (o === HeaderButtonTypeEnum.CONFIRM) {
        this.save();
        this.internalService.setOnChange(null);
      } else if (o === HeaderButtonTypeEnum.CANCEL) {
        this.cancel();
      }
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private loadData(): void {
    this.groupedDataSignal.set(null);
    this.subs.add(this.dataVMService.getGroupedDataWithBaseline().pipe(
      tap((baselineData: GroupedData | null) => {
          if (baselineData) {
            this.baselinesDataWithReg = baselineData;
            const timeZone = baselineData.regConfig ? baselineData.regConfig.timeZone : 'UTC';
            this.timezoneAbbr = moment.tz(timeZone).zoneName();

            this.dataSource.data = baselineData.values ?? [];
            const startDate = baselineData.selectedDateRange?.start
              ? moment.tz(baselineData.selectedDateRange?.start?.format('YYYY-MM-DD HH:mm:ss'), timeZone)
              : moment.tz(new Date(), timeZone).startOf('day');

            this.dates = [];
            for (let i = 0; i < 7; i++) {
              const newDate = startDate.clone().add(i, 'days');
              this.dates.push(newDate.date());
            }
            // Set the grouped data signal (now data is loaded)
            this.groupedDataSignal.set(baselineData);
            this.internalService.setErrorLoadingData(false);
            this.cdr.detectChanges();
          }
      }),
      catchError((error: HttpErrorResponse) => {
        this.groupedDataSignal.set(null);
        console.error('Error:', error);
        return EMPTY;
      })
    ).subscribe());
  }

  /**
   * This method is updated each time you enter a value on any of the input field
   * @param element provides the whole element which is being updated
   * @param event is an KeyboardEvent where we get the value entered.
   * @param week is used to provide the week which we are updating the value for.
   */
  inputToCellValue(element: NgxIntervalDataGridRowModel, inputEvent: KeyboardEvent, week: string) {
    const target = inputEvent?.target as HTMLInputElement | null;
    const value: number | null = target?.valueAsNumber === undefined || isNaN(target.valueAsNumber) ? null : target?.valueAsNumber;
    const updatedDataElement = this.ngxService.updateElementValue({
      weekName: week as Week,
      timePeriodKey: element.timePeriodKey,
      value,
      invalid_value: false,
      tableDataType: TableDataTypes.BASELINE
    });
    this.serverData = [...updatedDataElement];
  }

  /**
   * This method is used when user cancels from edit mode. Here we forget all his previous updates and take data we recieved from the server.
   */
  cancel() {
    const data: DataModel[] = this.ngxService.getUntouchedDataFromServer().dataModelRef[TableDataTypes.BASELINE] as DataModel[];
    this.ngxService.clearUnSavedData();
    this.dataSource.data = this.ngxService.groupByDayOfWeek(data, this.baselinesDataWithReg?.regConfig?.timeZone ?? 'UTC', TableDataTypes.BASELINE)
  }

  /**
   * This is the main method used to save the updated data and switch back to the readonly mode
   */

  save() {
    console.warn(' this.differencesData', this.ngxService.unsavedData);

    this.internalService.editMode$.next(false);
    const unsavedOffers: DetailTableCellModel[] = this.dataVMService.lookForDSTDuplication(this.ngxService.unsavedData, this.baselinesDataWithReg?.regConfig?.timeZone as string);
    const baselineToPost: BaselineRequest[] =
    this.dataModelService.getBaselineToPost(unsavedOffers, this.baselinesDataWithReg);
    this.postBaseline(baselineToPost);
  }

  postBaseline(baseline: BaselineRequest[]) {
    const regId = baseline.length ? baseline[0].registration_id : '';
    // Refresh data when success or when error (will also clearUnSavedData)
    this.dataVMService.postBaseline(baseline, regId)
      .subscribe({
        next: () => {
          this.messageService.handleSuccess("Successfully submitted baselines for a given time period.");
          this.dataVMService.refreshData(TableDataTypes.BASELINE)
        },
        error: () => this.dataVMService.refreshData(TableDataTypes.BASELINE)
      });
  }

  applyBulk(): void {
    this.subs.add(
      this.internalService.getBulkInputs$.subscribe((b: BulkInput | null) => {
        if (!b) return;
        switch (b.bulkType) {
          case BulkType.CUSTOM:
            {
              if (b.inputType === InputTypeEnum.VALUE && (b.value !== null && b.value !== undefined)) {
                this.serverData = [...this.ngxService.updateValueBulk(BulkType.CUSTOM, b.value,TableDataTypes.BASELINE, undefined, moment(b.startDateAndTime), moment(b.endDateAndTime))];
              }
            }
            break;
          case BulkType.DAY:
            if (b.value !== null && b.value !== undefined) this.serverData = [...this.ngxService.updateValueBulk(BulkType.DAY, b.value, TableDataTypes.BASELINE, b.week)];
            break;
          case BulkType.WEEK:
            if (b.value !== null && b.value !== undefined) this.serverData = [...this.ngxService.updateValueBulk(BulkType.WEEK, b.value, TableDataTypes.BASELINE)];
            break;
        }
        console.warn('this.serverData', this.serverData);
        this.dataSource = new MatTableDataSource<NgxIntervalDataGridRowModel>(this.serverData);
        this.cdr.detectChanges();
      })
    );
  }
}
