<div class="tabs">
  <div class="small-height toggle-group-design">
    <mat-button-toggle-group [(ngModel)]="selectedOption" (change)="onTabChange($event)" name="tabs" aria-label="Tabs">
      <ng-container *ngFor="let option of visibleOptions()">
        <mat-button-toggle [value]="option">
          {{ option.name }}
        </mat-button-toggle>
      </ng-container>
    </mat-button-toggle-group>
  </div>
</div>

<div>
  <p class="title">{{ selectedOption?.descriptionTitle }}</p>
  <p class="content-body">{{ selectedOption?.descriptionText }}</p>
</div>
