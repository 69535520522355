import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewInterface, OfferResponse } from '@dr-customer-offers-ui/lib-interfaces';
import { ApiEndpointsService } from '@dr-customer-offers-ui/lib-services';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NgxDateRangePickerOutput } from 'ngx-date-range-picker';
import { NgxDropdownOption } from 'ngx-dropdown';
import { NgxIntervalDataGridService } from 'ngx-interval-data-grid';
import { CookieService } from 'ngx-shared-services';
import { filter, Observable, of, Subscription, switchMap, take, tap } from 'rxjs';
import { environment } from '../environments/environment';
import { ExportReportComponent } from './components/dialogs/export/export-report.component';
import { IconSize } from './shared/icons/icons-size.enum';
import { ExportReportOutput, FilterSelectionInterface, MinuteFrequencyOption, RegConfig, TabOption, TabOptionsEnum } from './shared/models';
import { UserPreference } from './shared/models/user-preference';
import { ExportCSVService } from './shared/services/csv-export.service';
import { DataViewModelService } from './shared/services/data-vm.service';
import { InternalService } from './shared/services/internal.service';
import { LoaderService } from './shared/services/loader.service';
import { MixPanelService } from './shared/services/mixpanel.service';

@Component({
  selector: 'dr-customer-offers-ui-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private subs = new Subscription();
  protected TabOptionsEnum = TabOptionsEnum;
  protected appTitleValue!: NewInterface;
  protected navUrl!: string;
  protected selectedTabOption!: TabOption;
  protected editMode$: Observable<boolean> = this.internalState.editMode$.asObservable();
  protected isLoading$: Observable<boolean> = this.loader.isLoading$;
  protected selectedWeekRange!: NgxDateRangePickerOutput;
  public errorLoadingData$: Observable<boolean> = this.internalService.getErrorLoadingData;
  private firstEdit = true;
  protected exportIconSize: IconSize = IconSize.Large;
  protected selectedRegFormControl$: Observable<NgxDropdownOption> = this.dataVMService.selectedRegFormControl.valueChanges;
  protected isGroupedOffersSignal = this.dataVMService.isGroupedOffersSignal;
  errorMessageTitle = "Oh snap!"
  message = "You do not have Authorization to view offers for this Registration."

  // -------------------------
  // Values from configuration
  protected minDate!: moment.Moment;
  protected maxDate!: moment.Moment;
  private minuteFrequency!: MinuteFrequencyOption;
  // -------------------------

  protected tabOptions: TabOption[] = [
    {
      show: true,
      route: 'open',
      name: this.translate.instant('COM.TABS.OPEN.TAB_TITLE'),
      value: TabOptionsEnum.Open,
      selected: true,
      descriptionTitle: this.translate.instant('COM.TABS.OPEN.TITLE'),
      descriptionText: this.translate.instant('COM.TABS.LABELS.GENERAL_DESCRIPTION'),
      showEditButton: true,
      bulkFunctionality: true,
      exportFunctionality: true,
      optOut: true
    }
  ];

  constructor(
    @Inject(ApiEndpointsService)
    private api: ApiEndpointsService,
    public dialog: MatDialog,
    private internalService: InternalService,
    private translate: TranslateService,
    private cookieService: CookieService,
    private exportCSVService: ExportCSVService,
    private internalState: InternalService,
    private dataVMService: DataViewModelService,
    private mixPanelService: MixPanelService,
    public loader: LoaderService,
    private ngxService: NgxIntervalDataGridService
  ) {}

  ngOnInit(): void {
    this.navUrl = this.api.globalNavUrl;
    this.appTitleValue = {
      id: 0,
      value: 'APP.TITLE'
    };
    const { defaults } = environment;
    const locale = this.cookieService.getI18NLocale() || defaults.locale;
    const apiLocale = this.cookieService.getRawI18nLocale() || defaults.apiLocale;
    const brand = this.cookieService.getI18NBrand() || defaults.brand.toUpperCase();
    this.translate.setDefaultLang(`${defaults.locale}.${defaults.brand.toUpperCase()}`);
    this.translate.use(`${locale}.${brand}`);
    const userPreference: UserPreference = {
      locale,
      apiLocale: apiLocale,
      brand: brand
    };
    this.internalService.userPreferenceSignal.set((userPreference))
    this.selectedTabOption = this.tabOptions.find((option: TabOption) => option.selected) as TabOption;
    this.subs.add(
      this.editMode$.subscribe((edit) => {
        if (!this.firstEdit) {
          if (edit) {
            this.mixPanelService.editTable(this.selectedTabOption.value);
          } else {
            this.mixPanelService.cancelEditTable(this.selectedTabOption.value);
          }
          this.firstEdit = false;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  protected tabChanged(selectedTabOption: TabOption): void {
    // Close "edit mode" and remove any unsaved data
    this.internalState.editMode$.next(false);
    this.tabOptions.forEach((tabOption: TabOption) => {
      tabOption.selected = tabOption.value === selectedTabOption.value;
    });
    this.selectedTabOption = selectedTabOption;
    this.ngxService.clearUnSavedData();
  }


  protected openExportFunctionalityDialog(): void {
    const dialogRef = this.dialog.open(ExportReportComponent, {
      data: {
        minuteFrequency: this.minuteFrequency,
        minDate: this.minDate,
        maxDate: this.maxDate
      },
      width: '57.4rem',
      disableClose: false,
      hasBackdrop: true
    });

    dialogRef.afterClosed().pipe(
      filter((output: ExportReportOutput) => output !== null), // Adjust the property check as needed
      switchMap((output: ExportReportOutput) => {
        return this.internalService.getFilterSelectionState
          .pipe(
            switchMap((fData: FilterSelectionInterface | null) => {
              if (!fData) return of(null);
              return this.dataVMService.getRegViewConfig().pipe(
                switchMap((selectedReg: RegConfig | null) => {
                  return this.dataVMService.getExportOffers(output).pipe(
                    tap((o:  {offers: OfferResponse[] | null, timeZone: string } | null) => {
                      const _selectedReg: NgxDropdownOption = {
                        id: selectedReg?.registrationId ?? '',
                        value: selectedReg?.display_labels ?? ''
                      };
                      this.exportCSVService.exportCSV(output , o?.timeZone, _selectedReg, o?.offers, this.selectedTabOption.value)
                    })
                  )
              })
              )
            }))
      }),
      take(1)
    ).subscribe();
  }
}
