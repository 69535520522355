import { Component, OnDestroy, signal, Signal, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxDateRangePickerOutput } from 'ngx-date-range-picker';
import { NgxDropdownComponent, NgxDropdownOption } from 'ngx-dropdown';
import { Observable, Subscription, tap } from 'rxjs';
import { FilterSelectionInterface } from '../../shared/models';
import { DataViewModelService } from '../../shared/services/data-vm.service';
import { InternalService } from '../../shared/services/internal.service';
import { MixPanelService } from '../../shared/services/mixpanel.service';

@Component({
  selector: 'dr-customer-offers-ui-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnDestroy {
  private subs = new Subscription();
  @ViewChild('ngx_program_dropdown', { static: true })
  ngxProgramDropdown!: NgxDropdownComponent;
  @ViewChild('ngx_reg_dropdown', { static: true })
  ngxRegDropdown!: NgxDropdownComponent;

  protected isProgramListLoaded = false;
  protected selectedDateFormControl = this.dataVMService.selectedDateFormControl;
  protected selectedProgramFormControl = this.dataVMService.selectedProgramFormControl;
  protected selectedRegFormControl = this.dataVMService.selectedRegFormControl;

  protected regSignal: Signal<NgxDropdownOption[] | null | undefined> = signal(null);
  public registrationsData$!: Observable<NgxDropdownOption[] | null>;

  protected filterSelectedObj: FilterSelectionInterface = {
    programId: '',
    registrationId: '',
    dateRange: {} as NgxDateRangePickerOutput
  };
  private isFirstTime = true;
  public programsData$: Observable<NgxDropdownOption[] | null> = this.dataVMService.getProgramsList().pipe(
    tap((programs) => {
      if(programs === null || !programs.length) {
        if(this.isProgramListLoaded) {
          this.isProgramListLoaded = false;
        }
        this.getRegistrationsUsingSessionToken();
      } else {
        this.isProgramListLoaded = true;
        this.selectedProgramFormControl.setValue(programs[0]);
        this.getRegsFromSelectedProgram(programs[0]);
      }
    })
  )

  programFirstTimeSelected = false;
  regFirstTimeSelected = false;
  periodSelected = false;

  constructor(
    private internalState: InternalService,
    private dataVMService: DataViewModelService,
    private mixPanelService: MixPanelService,
    private route: Router
  ) {
    this.subs.add(this.selectedProgramFormControl.valueChanges.pipe(
        tap((program: NgxDropdownOption | null) => {
          if (!program) return;
          this.getRegsFromSelectedProgram(program);
        })
      ).subscribe());

    this.subs.add(this.selectedRegFormControl.valueChanges.pipe(
      tap((reg: NgxDropdownOption | null) => {
        if(!reg) return;
        this.selectedRegistration(reg);
      })
    ).subscribe());
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private getRegsFromSelectedProgram(selectedOption: NgxDropdownOption[] | NgxDropdownOption): void {
    this.isFirstTime = true;
    if(Array.isArray(selectedOption)) return;
    this.filterSelectedObj = {...this.filterSelectedObj, programId: selectedOption.id};
    this.registrationsData$ = this.dataVMService.getRegistrationsList(this.filterSelectedObj.programId).pipe(
      tap((reg: NgxDropdownOption[] | null) => {
        if (!reg || reg.length === 0) {
          this.route.navigate(['']);
          this.selectedRegFormControl.setValue(null);
          this.filterSelectedObj = {...this.filterSelectedObj, registrationId: ''};
        }
        else this.selectedRegFormControl.setValue(reg[0]);
      })
    );

    // Do not trigger the mixpanel event for the first time.
    if(this.programFirstTimeSelected && selectedOption){
      this.mixPanelService.selectProgram(selectedOption.value);
      this.regFirstTimeSelected = false;
    }
    this.programFirstTimeSelected = true;
  }

  private getRegistrationsUsingSessionToken(): void {
    this.registrationsData$ = this.dataVMService.getRegistrationsListUsingSession().pipe(
      tap((reg: NgxDropdownOption[] | null) => {
        if (!reg || reg.length === 0) this.filterSelectedObj = {...this.filterSelectedObj, registrationId: ''};
        else this.selectedRegFormControl.setValue(reg[0]);
      })
    );
  }

  private selectedRegistration(selectedOption: NgxDropdownOption[] | NgxDropdownOption): void {
    if(Array.isArray(selectedOption)) return;
    this.filterSelectedObj = {...this.filterSelectedObj, registrationId: selectedOption.id};
    if(this.isFirstTime) this.apply();
    this.isFirstTime = false;

    // Do not trigger the mixpanel event the first time or when the user manually changes the PROGRAM.
    if(this.regFirstTimeSelected && selectedOption){
      this.mixPanelService.selectRegistration(selectedOption.value);
    }
    this.regFirstTimeSelected = true;
  }

  protected apply(): void {
    // Exit "edit mode" when apply the filters
    this.internalState.editMode$.next(false);
    const currentRoute = this.route.url.split('?')[0];
    // Navigate to the same route
    this.route.navigate([currentRoute]);
  }

}
