import { Route } from "@angular/router";
import { BaselinesTabTableComponent } from "./components/tables/baselines-tab/baselines-tab-table.component";
import { ClearedTabTableComponent } from "./components/tables/cleared-tab/cleared-tab-table.component";
import { DeclaredAvailabilityTabTableComponent } from "./components/tables/da-tab/da-tab-table.component";
import { OpenTabTableComponent } from "./components/tables/open-tab/open-tab-table.component";
import { ScheduleTabTableComponent } from "./components/tables/schedule-tab/schedule-tab-table.component";

export const appRoutes: Route[] = [
  { path: 'open', component: OpenTabTableComponent },
  { path: 'schedule', component: ScheduleTabTableComponent },
  { path: 'cleared', component: ClearedTabTableComponent },
  { path: 'baselines', component: BaselinesTabTableComponent },
  { path: 'da', component: DeclaredAvailabilityTabTableComponent },
  { path: '**', redirectTo: 'open' }
];
